<template>
  <section fluid>
    <v-dialog v-model="showModalAddEspecificacion" persistent max-width="1000">
      <v-card class="py-4">
        <v-card-text>
          <h5 class="text-h5 secondary--text mb-4">Agregar especificación</h5>

          <v-row>
            <v-col cols="12" sm="6">
              <v-select
                  outlined
                  clearable
                  item-value="id"
                  item-text="nombre"
                  label="Unidad de medida *"
                  :items="listUnidadMedida"
                  v-model="form.id_unidad_medida"
                  :error-messages="errorUnidadMedida"
                  @input="$v.form.id_unidad_medida.$touch()"
                  @blur="$v.form.id_unidad_medida.$touch()"
                  @change="obtenerObsDetalleRecepcionOfertas"
              >
              </v-select>
            </v-col>
          </v-row>

          <v-text-field
              label="Nombre descriptivo del agrupador *"
              outlined
              v-model="form.nombre_agrupacion"
              :error-messages="errorNombreAgrupacion"
              @input="$v.form.nombre_agrupacion.$touch()"
              @blur="$v.form.nombre_agrupacion.$touch()"
              maxLength="75"
          />

          <v-textarea
              outlined
              label="Especificación *"
              v-model="form.especificacion"
              :error-messages="errorEspecificacion"
              @input="$v.form.especificacion.$touch()"
              @blur="$v.form.especificacion.$touch()"
              auto-grow
              maxLength="2024"
              counter="2024"
          ></v-textarea>

          <v-row dense class="mx-auto align-items-center" style="max-width: 1200px;">
            <v-col cols="12" md="6" class="px-0 py-0 flex-shrink-1 flex-grow-1 mr-4 mt-6">
              <v-text-field
                label="Buscar"
                placeholder="Buscar por nombre"
                outlined
                prepend-inner-icon="mdi mdi-magnify"
                v-model="form.nombre_solicitudes"
                @input="debouncedSearch"
              />
            </v-col>
            <div class="d-flex align-center" style="gap: 16px;">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn 
                    v-bind="attrs" 
                    v-on="on" 
                    color="primary" 
                    x-large
                    style="min-width: auto !important; padding: 8px 16px !important;"
                    @click.stop="limpiarFiltro"
                  >
                    <v-icon color="#FFF !important">mdi-backup-restore</v-icon>
                  </v-btn>
                </template>
                <span>Limpiar filtros</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn 
                    v-bind="attrs" 
                    v-on="on" 
                    color="primary" 
                    x-large
                    style="min-width: auto !important; padding: 8px 16px !important;"
                    @click.stop="ordenarAlfabeticamente"
                  >
                    <v-icon color="#FFF !important">mdi mdi-order-alphabetical-ascending</v-icon>
                  </v-btn>
                </template>
                <span>Ordenamiento alfabético</span>
              </v-tooltip>
            </div>
          </v-row>



          <v-divider class="mt-4 mb-4"></v-divider>

          <p v-show="listaDetalle.length === 0" :class="$v.selected.$dirty ? 'red--text' : '' + 'text-center ar'">
            No hay detalles disponibles
          </p>

          <div
              class="proceso-compra-element bgMinsal py-2 px-4 elevation-2 mb-4"
              v-for="data in listaDetalle"
              :key="data.id"
          >
            <h6 class="text-h6 secondary--text">{{ data.nombre_obs }}</h6>
            <p>{{ data.unidad }}</p>
            <p>Especificación: {{ data.especificacion }}</p>

            <v-row class="mt-2">
              <v-col cols="12" sm="2">
                <p>Cantidad</p>
                <p>{{ data.total_obs }}</p>
              </v-col>
              <v-col cols="12" sm="3">
                <p>Unidad de medida</p>
                <p>{{ data.unidad_medida }}</p>
              </v-col>
              <v-col cols="12" sm="3">
                <p>Precio unitario</p>
                <p>
                  {{
                    Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(data.precio_unitario)
                  }}
                </p>
              </v-col>
              <v-col cols="12" sm="2">
                <p>Total</p>
                <p>
                  {{
                    Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(data.monto_obs)
                  }}
                </p>
              </v-col>
              <v-col cols="12" sm="2" class="text-center">
                <v-checkbox
                    v-model="selected"
                    :value="data.id"
                    :error-messages="errorListDetalle"
                    @change="$v.selected.$touch()"
                ></v-checkbox>
              </v-col>
            </v-row>
          </div>

          <div class="text-center mt-16">
            <v-btn
                outlined
                color="secondary"
                @click="setShowModalAddEspecificacion(false)"
                min-width="30%"
                class="mr-4"
            >
              Cancelar
            </v-btn>
            <v-btn color="secondary" @click="agregarSubprocesoObs" min-width="30%"> Guardar</v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <ConfirmationDialogComponent
        :show="modalConfirmar"
        btnConfirmar="Aceptar"
        title="¿Desea eliminar el subproceso seleccionado?"
        message="De confirmarse la siguiente acción, los cambios no serán reversibles."
        @close="modalConfirmar = false"
        @confirm="eliminarSubproceso"
    />
  </section>
</template>

<script>
import {mapState, mapActions, mapMutations} from "vuex";
import {required} from "vuelidate/lib/validators";
import ConfirmationDialogComponent from "@/components/ConfirmationDialogComponent.vue";
import { debounce } from 'lodash';

export default {
  name: "AgregarEspecificacionComponent",
  components: {
    ConfirmationDialogComponent,
  },
  data: () => ({
    listaDetalle: [],
    listUnidadMedida: [],
    listUnidadOrg: [],
    subprocesos: [],
    obs: {},
    form: {
      nombre_solicitudes: null,
      nombre_agrupacion: null,
      especificacion: null,
      id_unidad_medida: null,
      id_unidad_organizacional: null,
      detalles: [],
    },
    ordenar: false,
    headers: [
      {
        text: "Especificación",
        align: "center",
        sortable: false,
        value: "nombre_agrupacion",
        width: "30%",
      },
      {
        text: "Cantidad",
        align: "center",
        value: "total_sub_proceso",
        width: "20%",
      },
      {
        text: "Monto ($)",
        align: "center",
        value: "monto_sub_proceso_obs",
        width: "20%",
      },
      {text: "Acciones", align: "center", value: "actions", width: "20%"},
    ],
    modalConfirmar: false,
    selected: [],
  }),
  validations: {
    form: {
      nombre_agrupacion: {required},
      especificacion: {required},
      id_unidad_medida: {required},
    },

    selected: {required},
  },
  computed: {
    ...mapState("procesoCompraDoc", [
      "showModalAddEspecificacion",
      "showModalAddEspecificacion",
      "showAddEspecificacionHerramienta",
      "datosLoteSeleccionado",
    ]),

    errorNombreAgrupacion() {
      const errors = [];
      if (!this.$v.form.nombre_agrupacion.$dirty) return errors;
      !this.$v.form.nombre_agrupacion.required && errors.push("Nombre de agrupación es requerido");
      return errors;
    },
    errorEspecificacion() {
      const errors = [];
      if (!this.$v.form.especificacion.$dirty) return errors;
      !this.$v.form.especificacion.required && errors.push("La especificación es requerida");
      return errors;
    },
    errorUnidadMedida() {
      const errors = [];
      if (!this.$v.form.id_unidad_medida.$dirty) return errors;
      !this.$v.form.id_unidad_medida.required && errors.push("La unidad de medida es requerida");
      return errors;
    },
    errorListDetalle() {
      const errors = [];
      if (!this.$v.selected.$dirty) return errors;
      this.selected.length < 1 && errors.push("Seleccione al menos un ítem");
      return errors;
    },
  },
  methods: {
    ...mapActions("procesoCompra", [
      "getObsRecepcionOfertas",
      "getObsDetalleRecepcionOfertas",
      "postSubprocesoObsRecepcionOfertas",
      "postSubprocesoObsRecepcionOfertasHerramienta",
      "getInfoSubprocesoObs",
      "putSubprocesoRecepcionOferta",
      "deleteSubprocesoRecepcionOferta",
    ]),
    ...mapActions("procesoCompraDoc", ["getLote"]),

    ...mapMutations("procesoCompraDoc", ["setShowModalAddEspecificacion"]),

    async obtenerListaMedida() {

      const unidadMedida = await this.services.Presentations.getListPresentations();
      this.listUnidadMedida = unidadMedida?.data;

    },

    async obtenerUnidadesOrg() {

      const unidadOrg = await this.services.UnidadesServices.getAllUnidades();
      this.listUnidadOrg = unidadOrg?.data;

    },

    async obtenerObsDetalleRecepcionOfertas(change = true) {

      let filters = {
        id_proceso_compra: this.$route.params.idProceso,
        body: {
          id_presentacion: this.form.id_unidad_medida,
          id_unidad: this.form.id_unidad_organizacional,
          busqueda: this.form.nombre_solicitudes,
        },
      };

      if (this.ordenar) {
        filters.body.ordenar = this.ordenar;
      }

      if (this.subprocesoId !== null || this.isEdit) filters.body.id_sub_proceso_obs = this.subprocesoId;

      const {data} = await this.getObsDetalleRecepcionOfertas(filters);
      this.listaDetalle = data;

      if (this.subprocesoId || this.isEdit) {
        data.forEach((detalle) => this.selected.push(detalle.id));
      }

      if (change) {
        this.selected = [];
        this.form.detalles = [];
      }

    },
    async busqueda(){
      this.obtenerObsDetalleRecepcionOfertas();
    },
    async limpiarFiltro(){
      this.form.nombre_solicitudes = null;
      this.obtenerObsDetalleRecepcionOfertas();
    },
    async ordenarAlfabeticamente(){
      this.ordenar = !this.ordenar;
      this.obtenerObsDetalleRecepcionOfertas();
    },

    async agregarSubprocesoObs() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      this.form.detalles = this.selected;
      this.form.id_lote = this.datosLoteSeleccionado.id;
      let response;
      let data = {body: this.form};


      if (this.isEdit) {
        data.id_sub_proceso_obs = this.subprocesoId;
        response = await this.putSubprocesoRecepcionOferta(data);
      } else {
        data.id_proceso_compra = this.$route.params.idProceso;
        response = this.showAddEspecificacionHerramienta 
          ? await this.postSubprocesoObsRecepcionOfertasHerramienta(data) 
          : await this.postSubprocesoObsRecepcionOfertas(data);
      }

      if (response.status == 201 || response.status == 200) {
        await this.getLote({
          id_lote: this.datosLoteSeleccionado.id,
        });
        this.setShowModalAddEspecificacion(false);
        this.reset();
        this.temporalAlert({
          show: true,
          type: "success",
          message: response?.data.message,
        });
      }

    },

    async eliminarSubproceso() {
      this.modalConfirmar = false;
      const response = await this.deleteSubprocesoRecepcionOferta(this.subprocesoId);
      if (response.status == 200) {
        await this.getLote({
          id_lote: this.datosLoteSeleccionado.id,
        });
        this.temporalAlert({
          show: true,
          type: "success",
          message: response?.data?.message,
        });
      }
    },

    reset() {
      this.selected = [];
      this.listaDetalle = [];
      this.form = {
        nombre_agrupacion: null,
        especificacion: null,
        id_unidad_medida: null,
        id_unidad_organizacional: null,
        detalles: [],
      };
      this.$v.$reset();
      if (this.isEdit) {
        this.subprocesoId = null;
        this.isEdit = false;
      }
    },
  },
  watch: {
    async showModalAddEspecificacion(value) {
      if (value) {
        await this.obtenerListaMedida();
        await this.obtenerUnidadesOrg();
        await this.obtenerObsDetalleRecepcionOfertas(false);
      }
    },
  },
  created(){
    this.debouncedSearch = debounce(this.busqueda, 600);
  }
};
</script>
