<template>
  <v-card>
    <v-card-actions>
      <v-row class="w-100 align-center">
        <!-- Componente ValidarEdicion -->
        <v-col cols="12">
          <ValidarEdicion
            :id_proceso="+this.id_proceso"
            :id_modificativa="13"
            @validar="validar"
          />
        </v-col>

        <v-col cols="12" md="6" class="d-flex flex-wrap justify py-4">
          <v-text-field
            v-model="loteSelected"
            label="Buscar lote"
            prepend-inner-icon="mdi-magnify"
            outlined
            dense
            solo
            hide-details
            clearable
            @input="debouncedGetLote"
          />
        </v-col>

        <!-- Contenedor de botones -->
        <v-col cols="12" class="d-flex flex-wrap justify" style="gap: 16px;">
          <v-btn
            :disabled="!validado && !esRolConfigurarLotes"
            v-if="
              ([11].includes(procesoData.id_seguimiento_proceso) &&
                !esRolConfigurarLotes) ||
              (validado && esRolConfigurarLotes)
            "
            color="secondary"
            class="flex-grow-1 flex-md-grow-0"
            dark
            @click="openModalAgregarLote"
          >
            <v-icon>mdi-plus</v-icon>
            Agregar lote
          </v-btn>

          <v-btn
            v-if="esRolConfigurarLotes || esRolLecturaConfigurarLotes"
            color="secondary"
            class="flex-grow-1 flex-md-grow-0"
            dark
            @click="abrirModalParticipaciones"
          >
            <v-icon>mdi-eye-plus-outline</v-icon>
            Revisión de participaciones
          </v-btn>

          <v-btn
            v-if="esRolConfigurarLotes || esRolLecturaConfigurarLotes"
            color="secondary"
            class="flex-grow-1 flex-md-grow-0"
            dark
            @click="modalRevisionEvaluacion"
          >
            <v-icon>mdi-check-outline</v-icon>
            Revisión de evaluación
          </v-btn>

          <v-btn
            v-if="esRolConfigurarLotes || esRolLecturaConfigurarLotes"
            color="secondary"
            class="flex-grow-1 flex-md-grow-0"
            dark
            @click="abrirModalOfertas"
          >
            <v-icon>mdi-file-eye-outline</v-icon>
            Revisión de Ofertas
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>

    <v-card-text>
      <EmptyComponent :empty="processWithNoLotes">
        <p v-if="lotesLoading" class="pb-0 mb-4 text-center">Cargando lotes...</p>
        <v-expansion-panels v-model="loteSeleccionado">
          <v-expansion-panel v-for="(lote, index) in lotes" :key="lote.id" :value="index">
            <v-expansion-panel-header>
              {{ lote.nombre }} 
              <div class="text-right">
                <v-btn
                  v-if="
                    (procesoData.id_seguimiento_proceso === 11 &&
                      !esRolConfigurarLotes &&
                      validado) ||
                    (esRolConfigurarLotes && validado)
                  "
                  icon @click.stop="setShowModalDeleteLote(true), setIdLoteAccion(lote.id)"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-col cols="12" sm="8">
                <v-card>
                  <v-card-text>
                    <v-form>
                      <v-text-field
                        outlined
                        label="Nombre del lote *"
                        v-model="lote.nombre"
                      />
                      <v-text-field
                        outlined
                        label="Descripción"
                        v-model="lote.descripcion"
                      />
                      <v-btn
                        outlined
                        color="secondary"
                        class="mr-4"
                        min-width="30%"
                        :disabled="noPuedeModificar"
                        @click="updateDatosLote(lote)"
                      >
                        Actualizar lote
                      </v-btn>

                      <v-btn
                        color="error"
                        class="mr-4"
                        min-width="30%"
                        :disabled="noPuedeModificar"
                        :style="{
                          color: noPuedeModificar ? 'black' : 'white !important',
                        }"
                        @click="openModalSepararAgrupador(lote.id)"
                      >
                        <v-icon
                          left
                          dark
                          >
                          mdi-alert
                        </v-icon>
                        Separar en lote por agrupador
                      </v-btn>

                      <v-btn
                        :disabled="(!validado || esRolConfigurarLotes && [11].includes(procesoData.id_seguimiento_proceso)) || (esRolLecturaConfigurarLotes && !esRolConfigurarLotes)"
                        color="secondary"
                        min-width="30%"
                        v-if="[11].includes(procesoData.id_seguimiento_proceso) || (esRolConfigurarLotes)"
                        @click="setShowModalAddEspecificacion({show: true, herramienta: true})"
                      >
                        Agregar especificación
                      </v-btn>
                    </v-form>

                    <v-divider class="mt-4 mb-4" />

                    <v-data-table
                      :headers="headers"
                      :items="agrupacionesLote"
                      no-data-text="No hay datos para mostrar"
                    >
                      <template #[`item.monto_sub_proceso_obs`]="{ item }">
                        {{
                          Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD", 
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 8,
                          }).format(item?.monto_sub_proceso_obs)
                        }}
                      </template>
                      <template #[`item.acciones`]="{ item }">
                        <app-menu-acciones
                          :menu="getAcciones(item)"
                          titulo="Acciones"
                          :elemento="item"
                        />
                      </template>
                    </v-data-table>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </EmptyComponent>

      <!-- Modal para observar las participaciones de proveedores a lotes -->
      <v-dialog v-model="modalParticipaciones" max-width="85vw" persistent>
        <v-card class="py-6 px-6 position-relative">
            <v-icon class="secondary--text close-icon" @click="closeModalParticipaciones">
              mdi-close
            </v-icon>
          <span class="text-h6 secondary--text d-block">
            Registro de participación de proveedores a lotes
          </span>
          <v-col>
            <v-btn
              :disabled="!validado && !esRolConfigurarLotes"
              v-if="
                ([11].includes(procesoData.id_seguimiento_proceso) &&
                  !esRolConfigurarLotes) ||
                (validado && esRolConfigurarLotes)
              "
              color="secondary"
              class="flex-grow-1 flex-md-grow-0"
              dark
              @click="abrirModalProveedores"
            >
              <v-icon>mdi-plus</v-icon>
              Agregar Participación
            </v-btn>
          </v-col>
          <!-- <v-divider class="mt-4 mb-4" /> -->
          <v-divider style="margin: 8px 0; height: 2px; background-color: gray;" />
          <v-card-text class="pa-0">
            <v-expansion-panels v-model="participacionSeleccionada" style="max-height: 60vh; overflow-y: auto;">
              <v-expansion-panel
                v-for="(participacionProveedor, index) in dataParticipacionesProveedores"
                :key="participacionProveedor.id"
                :value="index">
                <v-expansion-panel-header>
                  <v-row class="d-flex align-center justify-space-between">
                    <v-col cols="6">
                      <h6 class="text-subtitle-1 font-weight-bold">
                        {{ participacionProveedor.nombre_comercial }}
                      </h6>
                    </v-col>
                    <v-col cols="4" class="text-right">
                      <h6 
                        class="text-subtitle-1 font-weight-bold"
                        :class="participacionProveedor.tiene_oferta ? 'success--text' : 'error--text'"
                      >
                        {{ getOfertaStatusParticipacion(participacionProveedor.tiene_oferta) }}
                      </h6>
                    </v-col>
                  </v-row>
                </v-expansion-panel-header>
                <div style="max-height: 250px; overflow-y: auto; padding-right: 8px;">
                  <v-expansion-panel-content>
                    <div
                      v-for="(participacion, index) in dataParticipacionProveedor"
                      :key="participacion.id"
                      :value="index"
                      class="d-flex align-center"
                    >
                      <div>
                        <h6 class="text-subtitle-1 font-weight-bold">
                          {{ participacion.nombre }}
                        </h6>
                        <p class="mb-0 text-body-2">{{ participacion.descripcion }}</p>
                      </div>
                      <v-divider />
                      <v-switch class="ml-auto"
                        @change="
                          (value) =>
                            handleSwitchChange(
                              participacion.id,
                              participacionProveedor.tiene_oferta,
                              participacion.id_proceso_compra,
                              value,
                              participacionProveedor.id,
                            )
                        "
                        :input-value="participacion.participando"
                        :label="textSwitch(participacion.participando)"
                        color="success"
                        :disabled="!participacionProveedor.tiene_oferta || !validado"
                      >
                      </v-switch>
                    </div>
                  </v-expansion-panel-content>
                </div>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card-text>
        </v-card>
      </v-dialog>

      <!-- Modal para ver las ofertas de proveedores a lotes -->
      <v-dialog v-model="modalOfertas" max-width="85vw" persistent>
        <v-card class="py-6 px-6 position-relative">
            <v-icon class="secondary--text close-icon" @click="closeModalOfertas">
              mdi-close
            </v-icon>
          <span class="text-h6 secondary--text d-block my-4">
            Ofertas de proveedores a lotes
          </span>
          <v-divider style="margin: 8px 0; height: 2px; background-color: gray;" />
          <v-card-text class="pa-0">
            <v-expansion-panels v-model="ofertaSeleccionada" style="max-height: 60vh; overflow-y: auto;">
              <v-expansion-panel
                v-for="(participacionProveedor, index) in dataParticipacionesProveedores"
                :key="participacionProveedor.id"
                :value="index">
                <v-expansion-panel-header>
                  <v-row class="d-flex align-center justify-space-between">
                    <v-col cols="6">
                      <h6 class="text-subtitle-1 font-weight-bold">
                        {{ participacionProveedor.nombre_comercial }}
                      </h6>
                    </v-col>
                    <v-col cols="4" class="text-right">
                      <h6 
                        class="text-subtitle-1 font-weight-bold"
                        :class="participacionProveedor.tiene_oferta ? 'success--text' : 'error--text'"
                      >
                        {{ getOfertaStatusParticipacion(participacionProveedor.tiene_oferta) }}
                      </h6>
                    </v-col>
                  </v-row>
                </v-expansion-panel-header>

                <div style="max-height: 250px; overflow-y: auto; padding-right: 8px;">
                  <v-expansion-panel-content>
                    <v-data-table
                      :headers="headersOfertas"
                      :items="dataOfertasProveedor.length ? dataOfertasProveedor : [{}]"
                      no-data-text="No hay datos para mostrar"
                    >
                      <template #[`item.id_oferta`]="{ item }">
                        {{ item.id_oferta }}
                      </template>

                      <template #[`item.tipo_oferta`]="{ item }">
                        {{ item.tipo_oferta == null 
                          ? 'No agregó oferta' 
                          : (item.tipo_oferta === 1 
                              ? 'Técnica' 
                              : 'Económica'
                            ) 
                        }}
                      </template>

                      <template #[`item.subido_por`]="{ item }">
                        {{ item.subido_por ?? "No ha subido oferta" }}
                      </template>

                      <template #[`item.calificacion`]="{ item }">
                        {{ item.calificacion ?? "Pendiente" }}
                      </template>

                      <template #[`item.calificada_por`]="{ item }">
                        {{ item.calificada_por ?? "Sin calificar" }}
                      </template>

                      <template #[`item.participando`]="{ item }">
                        {{ item.participando ? "Participando" : "Sin participar" }}
                      </template>

                      <template #[`item.acciones`]="{ item }">
                        <app-menu-acciones 
                          :menu="getAccionesOfertas(item)" 
                          titulo="Acciones" 
                          :elemento="item" 
                        />
                      </template>
                    </v-data-table>
                  </v-expansion-panel-content>
                </div>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card-text>
        </v-card>
      </v-dialog>

      <!-- Modal para agregar una nueva oferta de un proveedor -->
      <v-dialog
        v-model="agregarOfertaAdjunto"
        width="900"
        persistent
        height="700"
      >
        <v-card height="600">
          <v-card-title class="pt-10 pl-10">
            <v-icon class="secondary--text" @click="cerrarModal">
              mdi-close
            </v-icon>
          </v-card-title>
          <v-card-text>
            <v-col cols="12" md="12">
              <p class="text-h6 font-museo-sans mb-0 secondary--text text-center">
                Subir un nuevo documento de oferta
              </p>
            </v-col>
            <v-form ref="formOferta" lazy-validation>
              <v-col cols="12">
                <vc-date-picker
                  v-model="form.calendar"
                  mode="datetime"
                  locale="es"
                  is24hr
                  :popover="config_calendar"
                  :max-date="$moment().format('YYYY-MM-DD HH:mm')"
                  :valid-hours="horas_disponibles"
                  @dayclick="horasDisponibles()"
                >
                  <template v-slot="{ inputEvents }">
                    <v-text-field
                      class="py-1 border roundend focus:outline-none focus:border-blue-300 inputError"
                      label="Fecha y hora de recepción *"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      :value="getFechaFin"
                      v-on="inputEvents"
                      outlined
                      clearable
                      :error-messages="dataVarValueErrors"
                    />
                  </template>
                </vc-date-picker>
              </v-col>

              <v-col cols="12">
                <v-select
                  label="Tipo de Oferta *"
                  outlined
                  class="inputError"
                  :items="ctlTipoOferta"
                  item-value="id"
                  item-text="nombre"
                  v-model="form.idTipoOferta"
                  :error-messages="tipoOfertaErrors"
                  @blur="$v.form.idTipoOferta.$touch()"
                />
              </v-col>

              <v-col cols="12">
                <v-file-input
                  v-model="form.adjunto"
                  label="PDF"
                  placeholder="Seleccione un PDF"
                  class="inputError"
                  outlined
                  light
                  @change="$v.form.adjunto.$touch()"
                  @blur="$v.form.adjunto.$touch()"
                  :error-messages="adjuntoErrors"
                  accept="application/pdf"
                />
              </v-col>
            </v-form>
          </v-card-text>
          <v-card-actions class="pl-10 pr-10 mt-8">
            <v-row>
              <v-col cols="6">
                <v-btn color="primary" block @click="cerrarModal">
                  Cancelar
                </v-btn>
              </v-col>
              <v-col cols="6">
                <v-btn
                  block
                  color="secondary"
                  @click="crearRegistroDocumentoOferta"
                >
                  Registrar
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Modal ver o agregar calificacion -->
      <v-dialog
        v-model="modalCalificacionOferta"
        width="700"
        persistent
        height="600"
      >
        <v-card height="500">
          <v-card-title class="pt-10 pl-10">
            <v-icon class="secondary--text" @click="cerrarModalCalificaciones">
              mdi-close
            </v-icon>
          </v-card-title>
          <v-card-text>
            <v-col cols="12" md="12">
              <p class="text-h6 font-museo-sans mb-0 secondary--text text-center">
                Visualizar la calificación de la oferta
              </p>
            </v-col>
            <v-form ref="formBaja" lazy-validation>
              <v-col cols="12">
                <v-text-field
                  v-model="calificacion_oferta"
                  outlined
                  class="inputError"
                  label="Calificación de oferta"
                  @input="$v.calificacion_oferta.$touch()"
                  :error-messages="calificacionErrors"
                />
              </v-col>

              <v-col
                cols="12"
                class="font-museo-sans mb-0 secondary--text text-center"
              >
                Al calificar esta oferta el proveedor podrá: <br />
                1- Ser adjudicado en los lotes asignados.<br />
                2- Participar en las subastas de los lotes asignados. <br /><br />
                <p
                  class="text-h6 font-museo-sans mb-0 secondary--text text-center"
                >
                  ¿Desea continuar?
                </p>
              </v-col>
            </v-form>
          </v-card-text>
          <v-card-actions class="pl-10 pr-10 mt-8">
            <v-row>
              <v-col cols="6">
                <v-btn color="primary" block @click="cerrarModalCalificaciones">
                  Cancelar
                </v-btn>
              </v-col>
              <v-col cols="6">
                <v-btn block color="secondary" @click="putCalificacionOferta">
                  modificar
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>

    </v-card-text>

    <!-- Modal para agregar Lotes -->
    <v-dialog v-model="modalAgregarLote" persistent max-width="600">
      <v-form ref="formLote" @submit.prevent="createLote" class="mt-4">
        <v-card class="py-4">
          <v-card-text>
            <h5 class="text-h5 secondary--text mb-4">Agregar lote</h5>

            <v-text-field
              v-model="formAgregar.nombre"
              label="Nombre del lote *"
              class="inputError"
              outlined
              required
              :rules="[rules.required]"
            />
            <v-textarea
              v-model="formAgregar.descripcion"
              label="Descripción"
              outlined
              auto-grow
              counter="500"
              maxlength="500"
            />
          </v-card-text>
          <v-card-actions class="d-flex justify-center">
            <v-btn
              outlined
              color="secondary"
              min-width="35%"
              @click="modalAgregarLote = false, clearData()"
              >Cancelar</v-btn
            >
            <v-btn solid color="secondary" min-width="35%" type="submit"
              >Agregar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <!-- Modal para ver la Evaluación -->
    <v-dialog persistent v-model="modalEvaluacion">
      <v-card>
        <v-card-text>
          <participacionesAuditoria :procesoData="procesoData2" />
        </v-card-text>
        <v-card-actions>
          <v-btn block color="primary" @click="modalEvaluacion = false">
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

      <!-- Modal para agregar la participacion del proveedor -->
    <v-dialog persistent v-model="modalProveedores" max-width="35vw">
      <v-card class="py-4 px-6">
        <v-card-text class="d-flex flex-column align-center">
          <span class="text-h6 secondary--text">
            Agregar participación de proveedor
          </span>
          <v-col cols="12" md="10">
            <v-autocomplete 
              v-model="busqueda"
              label="Escribe nombre, nombre comercial o documento"
              outlined
              clearable
              item-value="id" 
              item-text="nombre" 
              :items="proveedores"
              no-data-text="Sin coincidencias"
            />
          </v-col>

          <template v-if="proveedorSeleccionado">
            <v-row class="mt-2">
              <v-col cols="12">
                <v-label class="font-weight-bold">Nombre: </v-label>
                <span>{{ proveedorSeleccionado.nombre }}</span>
              </v-col>
              <v-col cols="12">
                <v-label class="font-weight-bold">Nombre comercial: </v-label>
                <span>{{ proveedorSeleccionado.nombre_comercial }}</span>
              </v-col>
            </v-row>
          </template>
        </v-card-text>

        <v-card-actions class="d-flex justify-end">
          <v-btn color="grey" @click="closeModalProveedores">
            Cerrar
          </v-btn>
          <v-btn 
            color="primary" 
            :disabled="!proveedorSeleccionado"
            @click="addParticipacionProveedor"
          >
            Agregar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="modalEditEspecificacion" persistent max-width="1000">
      <v-card class="py-4">
        <v-card-text>
          <h5 class="text-h5 secondary--text mb-4">Editar especificación</h5>

          <v-row>
            <v-col cols="12" sm="6">
              <v-autocomplete
                  class="inputError"
                  outlined
                  clearable
                  item-value="id"
                  item-text="nombre"
                  label="Unidad de medida *"
                  :items="listUnidadMedida"
                  v-model="formEspecificacion.id_unidad_medida"
                  :error-messages="errorUnidadMedida"
                  @input="$v.formEspecificacion.id_unidad_medida.$touch()"
                  @blur="$v.formEspecificacion.id_unidad_medida.$touch()"
              >
              </v-autocomplete>
            </v-col>
          </v-row>

          <v-text-field
              class="inputError"
              label="Nombre descriptivo del agrupador *"
              outlined
              clearable
              v-model="formEspecificacion.nombre_agrupacion"
              :error-messages="errorNombreAgrupacion"
              @input="$v.formEspecificacion.nombre_agrupacion.$touch()"
              @blur="$v.formEspecificacion.nombre_agrupacion.$touch()"
              maxLength="75"
          />

          <v-textarea
              class="inputError"
              outlined
              clearable
              label="Especificación *"
              v-model="formEspecificacion.especificacion"
              :error-messages="errorEspecificacion"
              @input="$v.formEspecificacion.especificacion.$touch()"
              @blur="$v.formEspecificacion.especificacion.$touch()"
              auto-grow
              maxLength="2024"
              counter="2024"
          ></v-textarea>

          <div class="text-center mt-16">
            <v-btn
                outlined
                color="secondary"
                @click="closeModalEditEspecificacion"
                min-width="30%"
                class="mr-4"
            >
              Cancelar
            </v-btn>
            <v-btn color="secondary" @click="editSubprocesoObs" min-width="30%"> Guardar</v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <AgregarLoteComponent />
    <AgregarEspecificacionComponent />

    <PdfModal
      :isOpen="isPdfModalOpen"
      :source-loadable="pdfLoadable"
      @on-visibility-change="handlePdfModalVisibility"
      :filename="filename"
    />

    <ConfirmationModalComponent
      :isOpen="modalDeleteOferta"
      title="¿Desea eliminar la oferta seleccionada?"
      description="La eliminación de la oferta es irreversible, ¿Desea continuar?"
      @confirm="confirmarEliminacionOferta"
      @cancel="modalDeleteOferta = false"
    />

    <ConfirmationModalComponent
      :isOpen="showModalDeleteLote"
      title="¿Desea eliminar el lote seleccionado?"
      description="De confirmarse la siguiente acción, los cambios no serán reversibles."
      @confirm="enviarEliminacionLote"
      @cancel="setShowModalDeleteLote(false), setIdLoteAccion(null)"
    />

    <ConfirmationModalComponent
      :isOpen="modalSepararAgrupador"
      title="¿Desea separar en lotes los agrupadores?"
      description="De confirmarse la siguiente acción, los cambios no serán reversibles."
      @confirm="enviarSeparacionAgrupador"
      @cancel="modalSepararAgrupador = false"
    />

    <ConfirmationModalComponent
      :isOpen="modalDeleteAgrupacion"
      title="¿Desea eliminar el registro seleccionado?"
      description="De confirmarse la siguiente acción, los cambios no serán reversibles."
      @confirm="confirmarEliminacionAgrupacion"
      @cancel="modalDeleteAgrupacion = false"
    />

  </v-card>
</template>
<script>
import ValidarEdicion from "../ValidarEdicion.vue";
import { mapActions, mapMutations, mapState } from "vuex";
import AgregarLoteComponent from "@/views/ProcesoCompraDoc/components/AgregarLoteComponent.vue";
import AgregarEspecificacionComponent from "@/views/ProcesoCompraDoc/components/AgregarEspecificacionComponent.vue";
import DataTableComponent from "@/components/DataTableComponent.vue";
import { EmptyComponent } from '@/components/utils';
import { validationMixin } from "vuelidate";
import { ConfirmationModalComponent } from "@/components/utils";
import PdfModal from "@/components/PdfModal.vue";
import {
  createLoadable,
  toggleLoadable,
  setLoadableResponse,
} from "@/utils/loadable";
import { required, minValue, maxValue } from "vuelidate/lib/validators";
import { convertToFormData } from "@/utils/data";
import participacionesAuditoria from "@/views/ProcesoCompraDoc/AuditoriaProcesos/components/ParticipantesComponent.vue";

export default {
  name: "ConfiguracionLotesProceso",
  mixins: [validationMixin],
  components: {
    ValidarEdicion,
    AgregarLoteComponent,
    AgregarEspecificacionComponent,
    ConfirmationModalComponent,
    DataTableComponent,
    EmptyComponent,
    PdfModal,
    participacionesAuditoria,
  },
  validations: {
    form: {
      idTipoOferta: { required },
      adjunto: { required },
      calendar: { required },
    },
    formEspecificacion: {
      id_unidad_medida: { required },
      nombre_agrupacion: { required },
      especificacion: { required },
    },
    calificacion_oferta: { maxValue: maxValue(100), minValue: minValue(1) },
  },
  data: () => ({
    validado: false,
    esEdicion: false,
    lotesLoading: false,
    loteSeleccionado: null,
    loteID: null,
    modalSepararAgrupador: null,
    loteSelected: null,
    timeout: null,
    modalDeleteAgrupacion: false,
    dataAgrupacion: null,
    modalParticipaciones: false,
    modalOfertas: false,
    modalProveedores: false,
    busqueda: null,
    dataParticipacionesProveedores: [],
    dataParticipacionProveedor: [],
    dataOfertasProveedor: [],
    proveedores: [],
    participacionSeleccionada: null,
    ofertaSeleccionada: null,
    idParticipacion: null,
    modalEditEspecificacion: false,
    listUnidadMedida: [],
    formEspecificacion: {
      nombre_agrupacion: null,
      especificacion: null,
      id_unidad_medida: null,
    },
    idSubProcesoObs: null,
    //PDF
    isPdfModalOpen: false,
    pdfLoadable: createLoadable(null),
    filename: "",
    agregarOfertaAdjunto: false,
    dataCrearOfertaParticipacionProveedor: null,
    //calificacion de oferta modal
    modalCalificacionOferta: false,
    calificacion_oferta: null,
    dataOferta: null,
    //eliminar oferta de proveedor
    modalDeleteOferta: false,
    dataOfertaEliminacion: null,
    form: {
      idTipoOferta: null,
      adjunto: null,
      calendar: null,
    },
    config_calendar: {
      visibility: "click",
    },
    horas_disponibles: {},
    //Para Agregar
    formAgregar: {
      nombre: null,
      descripcion: null,
    },
    rules: {
      required: (value) => !!value || "Este es un campo requerido",
    },
    modalAgregarLote: false,
    // Catalogo de oferta
    ctlTipoOferta: [
      {
        id: 1,
        nombre: "Oferta técnica",
      },
      {
        id: 2,
        nombre: "Oferta económica",
      },
    ],
    //Evaluacion
    modalEvaluacion: false,
    procesoData2: {},
    headersOfertas: [
      {
        text: "Proveedor",
        value: "nombre_proveedor",
        align: "start",
      },
      {
        text: "Tipo Oferta",
        value: "tipo_oferta",
        align: "start",
      },
      {
        text: "Subido por",
        value: "subido_por",
        align: "start",
      },
      {
        text: "Calificación",
        value: "calificacion",
        align: "start",
      },
      {
        text: "Calificada por",
        value: "calificada_por",
        align: "center",
      },
      {
        text: "Puede Participar",
        value: "participando",
        align: "start",
      },
      {
        text: "Acciones",
        value: "acciones",
        align: "center",
      },
    ],
  }),
  computed: {
    ...mapState("procesoCompraDoc", [
      "procesoData",
      "datosLoteSeleccionado",
      "lotes",
      "showModalDeleteLote",
    ]),
    id_proceso() {
      return +this?.$route?.params?.idProceso || 0;
    },
    noPuedeModificar() {
      return !this.validado || !this.esRolConfigurarLotes;
    },
    agrupacionesLote() {
      return this.datosLoteSeleccionado?.SubProcesoObs || [];
    },
    nombreLote() {
      return this.datosLoteSeleccionado?.nombre;
    },
    descripcionLote() {
      return this.datosLoteSeleccionado?.descripcion;
    },
    processWithNoLotes() {
      return this.lotesLoading && Array.isArray(this.lotes) && this.lotes.length > 0;
    },
    esRolConfigurarLotes() {
      return this.haveRoles(["ROLE_CONFIGURACION_LOTES_DINAC"]);
    },
    esRolLecturaConfigurarLotes() {
      return this.haveRoles(["ROLE_LECTURA_CONFIGURACION_LOTES_DINAC"]);
    },
    faltaOfertaParticipacion(){
      return (this.esRolLecturaConfigurarLotes && !this.esRolConfigurarLotes) 
      || (!this.validado && this.esRolConfigurarLotes);
    },
    proveedorSeleccionado() {
      return this.proveedores.find(p => p.id === this.busqueda) || null;
    },
    tieneOfertas() {
      return this.dataOfertasProveedor.length > 0;
    },

    headers() {
      const defaultHeaders = [
        { text: "Nombre", align: "center", value: "nombre_agrupacion" },
        { text: "Detalle", align: "center", value: "especificacion" },
        { text: "Cantidad", align: "right", value: "total_sub_proceso" },
        { text: "Monto", align: "right", value: "monto_sub_proceso_obs" },
      ];
      if (this.esRolConfigurarLotes) {
        // Si el usuario tiene el rol ROLE_CONFIGURACION_LOTES_DINAC, incluir la columna de acciones
        return [
          ...defaultHeaders,
          {
            text: "Acciones",
            align: "center",
            value: "acciones",
            sortable: false,
          },
        ];
      } else {
        // Si el usuario no tiene el rol ROLE_CONFIGURACION_LOTES_DINAC, devolver los headers por defecto
        return defaultHeaders;
      }
    },
    getFechaFin() {
      return this.form.calendar
        ? this.$moment(this.form.calendar, "DD/MM/YYYY hh:mm A").format(
            "DD-MM-YYYY hh:mm A"
          )
        : "";
    },
    tipoOfertaErrors() {
      const errors = [];
      if (!this.$v.form.idTipoOferta.$dirty) return errors;
      !this.$v.form.idTipoOferta.required && errors.push("Campo requerido");
      return errors;
    },
    dataVarValueErrors() {
      const errors = [];
      if (!this.$v.form.calendar.$dirty) return errors;
      !this.$v.form.calendar.required && errors.push("Campo requerido");
      return errors;
    },
    adjuntoErrors() {
      const errors = [];
      if (!this.$v.form.adjunto.$dirty) return errors;
      !this.$v.form.adjunto.required && errors.push("Campo requerido");
      return errors;
    },
    calificacionErrors() {
      const errors = [];
      if (!this.$v.calificacion_oferta.$dirty) return errors;
      !this.$v.calificacion_oferta.minValue &&
        errors.push("La calificación debe ser mayor a cero");
      !this.$v.calificacion_oferta.maxValue &&
        errors.push("La calificación debe ser entre los números 1 y 100");
      return errors;
    },
    errorNombreAgrupacion() {
      const errors = [];
      if (!this.$v.formEspecificacion.nombre_agrupacion.$dirty) return errors;
      if (this.$v.formEspecificacion.nombre_agrupacion.$error) {
        errors.push("Nombre de agrupación es requerido");
      }
      return errors;
    },
    errorEspecificacion() {
      const errors = [];
      if (!this.$v.formEspecificacion.especificacion.$dirty) return errors;
      if (this.$v.formEspecificacion.especificacion.$error) {
        errors.push("La especificación es requerida");
      }
      return errors;
    },
    errorUnidadMedida() {
      const errors = [];
      if (!this.$v.formEspecificacion.id_unidad_medida.$dirty) return errors;
      if (this.$v.formEspecificacion.id_unidad_medida.$error) {
        errors.push("La unidad de medida es requerida");
      }
      return errors;
    },
  },
  methods: {
    ...mapMutations("procesoCompraDoc", [
      "setShowModalAgregarLote",
      "setShowModalAddEspecificacion",
      "setShowModalDeleteLote",
      "setIdLoteAccion",
    ]),
    ...mapActions("procesoCompraDoc", [
      "getLotes",
      "getLote",
      "actualizarDatosLoteHerramienta",
      "deleteLoteHerramienta",
      "separarAgrupadorHerramienta",
      "guardarDatosLoteHerramienta",
    ]),
    validar(valor) {
      this.validado = valor;
    },
    tieneOferta(item){
      return Array.isArray(item?.oferta) && item.oferta.length > 0;
    },
    async cargarLotes(){
      try{
        this.lotesLoading = true;
        await this.getLotes({
          id_proceso_compra: this.id_proceso,
        });
      } finally{
        this.lotesLoading = false;
      }
    },
    async obtenerListaMedida() {
      const unidadMedida = await this.services.Presentations.getListPresentations();
      this.listUnidadMedida = unidadMedida?.data;
    },

    async editSubprocesoObs() {
      let form = {
        id_proceso_compra: this.id_proceso,
        id_sub_proceso_obs: this.idSubProcesoObs,
        id_unidad_medida: this.formEspecificacion.id_unidad_medida,
        nombre_agrupacion: this.formEspecificacion.nombre_agrupacion,
        especificacion: this.formEspecificacion.especificacion,
      };

      const { status } =
        await this.services.HerramientaModificacionLotes.editEspecificacionLoteHerramienta(form);
      if (status === 200) {
        this.temporalAlert({
          show: true,
          type: "success",
          message: "Especificación actualizada con éxito",
        });
        this.closeModalEditEspecificacion();
        this.getLote({ id_lote: this.idSubProcesoObs});
      }
    },
    debouncedGetLote() {
      clearTimeout(this.timeout); // Limpiar el temporizador anterior
      this.timeout = setTimeout(() => {
        this.getLotes({
          id_proceso_compra: this.id_proceso,
          filters: this.loteSelected,
        })
      }, 600);
    },
    //Para agregar lotes
    clearData() {
      this.formAgregar.nombre = null;
      this.formAgregar.descripcion = null;
      this.$refs.formLote.reset();
    },
    async createLote() {
      if (this.$refs.formLote.validate()) {
        await this.guardarDatosLoteHerramienta({
          id_proceso_compra: this.id_proceso,
          body: this.formAgregar,
        });
        this.clearData();
        this.modalAgregarLote = false;
      }
    },
    updateDatosLote(item){
      this.actualizarDatosLoteHerramienta({
        id_lote: item.id,
        nombre: item.nombre,
        descripcion: item.descripcion,
        id_proceso_compra: this.id_proceso,
        filters: this.loteSelected,
      })
    },
    // Para agregar lotes
    openModalAgregarLote(){
      this.modalAgregarLote = true;
    },
    // Mostrar modal de warning para separacion
    openModalSepararAgrupador(item){
      this.loteID = item;
      this.modalSepararAgrupador = true;
    },
    enviarSeparacionAgrupador(){
      this.separarAgrupadorHerramienta({
        id_lote: this.loteID,
        id_proceso_compra: this.id_proceso,
      })
      this.modalSepararAgrupador = false;
      this.cargarLotes();
    },
    enviarEliminacionLote(){
      this.deleteLoteHerramienta({
        id_proceso_compra: this.id_proceso,
        filters: this.loteSelected,
      }),
      this.cargarLotes();
    },
    // Mostrar modal para eliminar especificaciones de un lote
    showModalEliminar(item) {
      this.dataAgrupacion = item;
      this.modalDeleteAgrupacion = true;
      this.loteSeleccionado = null;
    },
    // eliminar especificaciones de un lote
    async confirmarEliminacionAgrupacion() {
      const idSubProcesoObs = this.dataAgrupacion.id;
      const { status } = await this.services.HerramientaModificacionLotes.eliminarAgrupadorHerramienta(
        idSubProcesoObs,
        { id_proceso_compra: this.id_proceso },
      );
      if (status === 200) {
        this.temporalAlert({
          show: true,
          type: "success",
          message: "Solicitud eliminada con éxito",
        });
        this.getLotes({
          id_proceso_compra: this.id_proceso,
        });
        this.modalDeleteAgrupacion = false;
        this.loteSeleccionado = null;
      }
    },
    // Modal para la revision de la evaluacion
    async modalRevisionEvaluacion() {
      const { data } =
        await this.services.PacProcesos.lotesParticipacionProveedoresEvaluacion(
          this.id_proceso
        );

      this.procesoData2 = { participantes: data };

      this.modalEvaluacion = true;
    },
    // Obtener la data de las participaciones
    async cargarParticipacionesLotes(){
      const { data } =
        await this.services.HerramientaModificacionLotes.lotesParticipacionesProveedoresHerramienta(
          this.id_proceso,
        );
      this.dataParticipacionesProveedores = data;
    },
    // Obtener la data de las participacion por proveedor
    async getParticipacionLotes(item){
      const { data } =
        await this.services.HerramientaModificacionLotes.lotesParticipacionProveedorHerramienta(
          item,
          this.id_proceso,
        );
      this.dataParticipacionProveedor = data;
    },
    // Obtener la data de las ofertas
    async getOfertasLotes(item){
      const { data } =
        await this.services.HerramientaModificacionLotes.getOfertasHerramienta(
          item,
        );
      this.dataOfertasProveedor = data;
    },
    // Obtener los proveedores
    async getProveedores(){
      const response = await this.services.HerramientaModificacionLotes.getProveedoresHerramienta(
          this.busqueda,
        );
      this.proveedores = response?.data;
    },
    //abrir el modal de Ofertas
    async abrirModalOfertas() {
      this.modalOfertas = true;
      this.cargarParticipacionesLotes();
      // this.getOfertasLotes();
    },
    // Cerrar modal de Ofertas
    async closeModalOfertas() {
      this.modalOfertas = false;
      this.ofertaSeleccionada = null;
    },
    //Abrir el modal revision de participaciones
    async abrirModalParticipaciones() {
      this.modalParticipaciones = true;
      this.cargarParticipacionesLotes();
    },
    // Cerrar modal de revision de participaciones
    async closeModalParticipaciones() {
      this.modalParticipaciones = false;
      this.participacionSeleccionada = null;
    },
    //Abrir el modal para agregar participacion proveedor
    async abrirModalProveedores() {
      this.modalProveedores = true;
      this.getProveedores();
    },
    // Cerrar modal para agregar participacion proveedor
    async closeModalProveedores() {
      this.busqueda = null;
      this.modalProveedores= false;
    },
    //Abrir el modal edicion de especificaciones
    async abrirModalEditEspecificacion(item) {
      this.formEspecificacion.id_unidad_medida = item.id_unidad_medida;
      this.formEspecificacion.nombre_agrupacion = item.nombre_agrupacion;
      this.formEspecificacion.especificacion = item.especificacion;
      this.idSubProcesoObs = item.id;
      this.modalEditEspecificacion = true;
      this.obtenerListaMedida();
    },
    // Cerrar el modal edicion de especificaciones
    async closeModalEditEspecificacion() {
      this.modalEditEspecificacion = false;
    },
    // Agregar participacion del proveedor
    async addParticipacionProveedor() {
      const { status } =
        await this.services.HerramientaModificacionLotes.agregarParticipacionHerramienta(
          this.id_proceso,
          this.proveedorSeleccionado.id_proveedor,
        );

      if (status === 200) {
        this.temporalAlert({
          show: true,
          type: "success",
          message: "Participación del proveedor agregada",
        });
        this.cargarParticipacionesLotes();
        this.closeModalProveedores();
      }
    },
    //Visualizar adjunto de oferta dentro del modal de revision de participaciones
    handlePdfModalVisibility(visible) {
      this.isPdfModalOpen = visible;
    },
    async loadAttachmentSolicitud(idOfertaProveedor) {
      this.isPdfModalOpen = true;
      toggleLoadable(this.pdfLoadable);
      const { data } = await this.services.PacProcesos.cargarDocumentoOferta(
        idOfertaProveedor
      );
      setLoadableResponse(this.pdfLoadable, data, { isFile: true });
    },
    //Cambiar estado de participacion en lotes dentro del modal de revision de participaciones
    async handleSwitchChange(id_lote, tiene_oferta, id_proceso_compra, value, id_participacion) {
      if (!tiene_oferta) {
        this.temporalAlert({
          show: true,
          type: "error",
          message:
            "No puede crear participación en lotes sin tener una oferta registrada.",
        });
        return;
      }
      const form = new FormData();
      form.append("id_lote", id_lote);
      form.append("tiene_oferta", tiene_oferta);
      form.append("id_proceso_compra", id_proceso_compra);
      form.append("value", value);
      form.append("id_participacion", id_participacion);

      const { status } =
        await this.services.HerramientaModificacionLotes.agregarParticipacionProveedorLoteHerramienta(form);
      if (status === 200) {
        this.temporalAlert({
          show: true,
          type: "success",
          message: "Participación al lote actualizada con éxito",
        });
        this.getParticipacionLotes(id_participacion);
        this.cargarParticipacionesLotes();
      }
    },
    //Mostrar el estado de la oferta dentro del modal de participaciones
    getOfertaStatusParticipacion(ofertas) {
      return ofertas ? "Oferta Registrada" : "No agregó Oferta";
    },
    //Mostrar el estado de la oferta dentro del modal de ofertas
    getOfertaStatus(ofertas) {
      if (!Array.isArray(ofertas) || ofertas.length === 0) {
        return "No agregó oferta";
      }

      return ofertas
        .map((oferta) => {
          if (oferta.puede_participar && oferta.calificacion_oferta) {
            return `Oferta evaluada y calificada con: ${oferta.calificacion_oferta}`;
          } else if (oferta.puede_participar && !oferta.calificacion_oferta) {
            return "Oferta registrada y no calificada";
          } else if (oferta) {
            return "Oferta registrada";
          } else {
            return "Oferta registrada y no evaluada";
          }
        })
        .join("\n");
    },
    //abrir el modal para agregar una nueva oferta dentro del modal de revision de participaciones
    async abrirModalAgregarOferta() {

      this.agregarOfertaAdjunto = true;
      this.dataCrearOfertaParticipacionProveedor = this.idParticipacion;
    },
    //metodo para crear una oferta nueva
    async crearRegistroDocumentoOferta() {
      this.$v.form.$touch();

      if (!this.$v.form.$invalid) {
        const id_participacion_proveedor =
          this.dataCrearOfertaParticipacionProveedor;

        const payload = {
          fecha: this.form.calendar,
          tipo_oferta: this.form.idTipoOferta,
          adjunto: this.form.adjunto,
        };
        const form = convertToFormData(payload);

        const { status } =
          await this.services.HerramientaModificacionLotes.agregarOfertaProveedorHerramienta(
            id_participacion_proveedor,
            form
          );

        if (status === 200) {
          this.temporalAlert({
            show: true,
            type: "success",
            message: "La oferta ha sido registrada con éxito",
          });
          this.cerrarModal();
          this.cargarParticipacionesLotes();
          this.getOfertasLotes(this.dataCrearOfertaParticipacionProveedor);
        }
      }
    },
    //Metodo para cerrar el modal de agregar una nueva oferta
    cerrarModal() {
      this.form.calendar = null;
      this.form.idTipoOferta = null;
      this.form.adjunto = null;
      this.agregarOfertaAdjunto = false;
    },

    // abrir el modal para visualizar las calificaciones de la oferta
    async abrirModalCalificacion(oferta) {
      this.modalCalificacionOferta = true;
      this.calificacion_oferta = oferta?.calificacion;
      this.dataOferta = oferta?.id_oferta;
    },
    // Metodo para cerrar el modal de ver las calificaciones de las ofertas
    cerrarModalCalificaciones() {
      this.calificacion_oferta = null;
      this.modalCalificacionOferta = false;
    },
    // modificar la calificacion de la oferta para registrar o editar
    async putCalificacionOferta() {
      this.$v.calificacion_oferta.$touch();

      if (!this.$v.calificacion_oferta.$invalid) {
        const payload = {
          calificacion_oferta: this.calificacion_oferta,
          id_proceso: this.id_proceso,
        };
        const form = convertToFormData(payload);
        const { status } =
          await this.services.HerramientaModificacionLotes.agregarCalificacionOfertaHerramienta(
            this.dataOferta,
            form,
          );

        if (status === 200) {
          this.temporalAlert({
            show: true,
            type: "success",
            message: "Calificación actualizada",
          });
          this.cerrarModalCalificaciones();
          this.cargarParticipacionesLotes();
          this.getOfertasLotes(this.idParticipacion);
        }
      }
    },
    // modal para eliminar la oferta que tiene un proveedor y eliminarla por completo
    async eliminarModalCalificacion(oferta) {
      this.modalDeleteOferta = true;
      this.dataOfertaEliminacion = oferta;
    },
    async confirmarEliminacionOferta() {
      const { status } = await this.services.HerramientaModificacionLotes.eliminarOfertaHerramienta(
        this.dataOfertaEliminacion,
        { id_proceso_compra: this.id_proceso },
      );
      if (status === 200) {
        this.temporalAlert({
          show: true,
          type: "success",
          message: "Oferta eliminada exitosamente",
        });
        this.modalDeleteOferta = false;
        this.cargarParticipacionesLotes();
        this.getOfertasLotes(this.idParticipacion);
      }
    },
    horasDisponibles() {
      let hora_maxima = [];

      let fecha_minima = null;

      if (this.form.calendar) {
        fecha_minima = this.form.calendar;
      } else {
        fecha_minima = new Date();
      }

      if (
        fecha_minima.getDate() === new Date().getDate() &&
        fecha_minima.getMonth() === new Date().getMonth() &&
        fecha_minima.getFullYear() === new Date().getFullYear()
      ) {
        hora_maxima = new Date().getHours();
      } else {
        hora_maxima = 23;
      }

      this.horas_disponibles = { max: hora_maxima, min: 0 };
    },
    getAcciones(item) {
      return [
        {
          nombre: "Editar especificación",
          icono: "mdi-pencil",
          callback2: () => this.abrirModalEditEspecificacion(item),
          disabled: this.noPuedeModificar,
        },
        {
          nombre: "Eliminar especificación",
          icono: "mdi-delete",
          callback2: () => this.showModalEliminar(item),
          disabled: this.noPuedeModificar,
        },
      ];
    },
    getAccionesOfertas(item) {
      return [
      {
          nombre: "Ver oferta de proveedor",
          icono: "mdi-eye",
          callback2: () => this.loadAttachmentSolicitud(item?.id_oferta),
          disabled: !this.tieneOfertas,
        },
        {
          nombre: "Subir oferta de proveedor",
          icono: "mdi-file-alert",
          callback2: () => this.abrirModalAgregarOferta(),
          disabled: this.noPuedeModificar,
        },
        {
          nombre: "Eliminar oferta de proveedor",
          icono: "mdi-file-document-remove",
          callback2: () => this.eliminarModalCalificacion(item?.id_oferta),
          disabled: this.noPuedeModificar || !this.tieneOfertas,
        },
        {
          nombre: "Ver calificación de oferta",
          icono: "mdi-clipboard-check-multiple",
          callback2: () => this.abrirModalCalificacion(item),
          disabled: this.noPuedeModificar || !this.tieneOfertas,
        },
      ];
    },
    setHeadersBasedOnRole() {
      this.HEADERS_CONST = [
        {
          align: 'center',
          text: "Documento",
          value: "nombre_documento",
        },
        {
          align: 'center',
          text: "Subido por",
          value: "Usuario.email",
        },
        {
          align: 'center',
          text: "Fecha de subida",
          value: "created_at",
        },
        {
          align: 'center',
          text: "Acciones",
          value: "acciones",
          sortable: false,
        },
      ];
    },
    textSwitch(item) {
      return item ? "Participando en lote" : "No participando";
    },
    textSwitchOri(lote) {
      if (lote && lote.participacion_aceptada) {
        return "Participando en lote - Participando en subasta";
      } else if (lote) {
        return "Participando en lote";
      } else {
        return "No participando";
      }
    },
  },
  watch: {
    loteSeleccionado(value_param) {
      if (value_param !== null && this.lotes[value_param]) {
        this.getLote({ id_lote: this.lotes[value_param].id });
      }
    },
    participacionSeleccionada(value_param){
      if (value_param !== null && this.dataParticipacionesProveedores[value_param]) {
        this.getParticipacionLotes(this.dataParticipacionesProveedores[value_param].id);
      }
    },
    ofertaSeleccionada(value_param){
      if (value_param !== null && this.dataParticipacionesProveedores[value_param]) {
        this.getOfertasLotes(this.dataParticipacionesProveedores[value_param].id);
        this.idParticipacion = this.dataParticipacionesProveedores[value_param].id;
      }
    },
  },
  mounted() {
    this.cargarLotes();
  },
};
</script>

<style>
  .inputError .v-messages__message{
    color: #ff5252 !important;
  }
  
  .close-icon {
    position: absolute !important;
    top: 12px;
    right: 12px;
    cursor: pointer;
  }
</style>