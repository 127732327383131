<template>
  <v-card>
    <v-card-actions>
      <v-row class="w-100">
        <v-col cols="12" md="6">
          <ValidarEdicion
            :id_proceso="+this.id_proceso"
            :id_modificativa="12"
            @validar="validar"
          />
        </v-col>

        <v-col cols="12" md="6" class="d-flex flex-column align-md-end">
          <v-switch
            v-model="borrado"
            label="Mostrar registros eliminados"
            color="success"
            @change="filtrar"
            class="mb-2"
          />
          <v-btn 
            v-if="validado"
            color="primary" 
            @click="abrirModalAgregar"
          >
            <v-icon class="white--text mr-2">mdi mdi-file-document-plus-outline</v-icon>
            Cargar nuevo documento 
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
    <v-card-text>
      <EmptyComponent :empty="processWithNoDocuments">
        <p v-if="documentsLoading" class="pb-0 mb-4 text-center">Cargando documentos...</p>
        <ArbolDocumentosProceso :arbol="list_docs_var">
          <template v-slot:documentos="{ documentos, tipo }">
            <DataTableComponent
              :getRowClass="getRowClass"
              class="my-4"
              :headers="HEADERS_CONST"
              :items="documentos"
              item-key="id"
              :tiene_paginacion="false"
              dense
              no-gutters
            >
              <template v-slot:[`item.created_at`]="{ item }">
                {{ formatDate(item.created_at) }}
              </template>

              <template v-slot:[`item.acciones`]="{ item }">
                <app-menu-acciones
                  :menu="getAcciones(item)"
                  titulo="Acciones"
                  :elemento="item"
                />
              </template>
            </DataTableComponent>
          </template>
        </ArbolDocumentosProceso>

      </EmptyComponent>

      <!-- Modal para actualizar archivo -->
      <v-dialog v-model="modalActualizar" max-width="500px" persistent>
        <v-card>
          <v-card-title class="headline">Editar Archivo</v-card-title>
          <v-card-text>
            <v-file-input
              class="inputAdjunto"
              v-model="documentoNuevo"
              accept="application/pdf"
              prepend-icon=""
              prepend-inner-icon="mdi-paperclip"
              label="Seleccionar archivo PDF *"
              outlined
              :error-messages="errorArchivo"
              @blur="$v.documentoNuevo.$touch()"
            ></v-file-input>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn text @click="cerrarModalUpdate">Cancelar</v-btn>
            <v-btn color="primary" @click="confirmUpdateFtn">Guardar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Modal para agregar archivo -->
      <v-dialog v-model="modalAgregar" max-width="1500" persistent>
        <v-card>
          <v-card-title class="headline">Agregar Archivo</v-card-title>
          <v-card-text>
            <v-row dense>
              <v-col cols="4">
                <v-text-field
                  v-model="nombre"
                  class="inputAdjunto"
                  label="Nombre del documento *"
                  outlined
                  maxlength="75"
                  :error-messages="nombreArchivoError"
                  @blur="$v.nombre.$touch()"
                  @input="$v.nombre.$touch()"
                />
              </v-col>
              <v-col cols="4">
                <v-file-input
                  v-model="documentoNuevo"
                  class="inputAdjunto"
                  accept="application/pdf"
                  prepend-icon=""
                  prepend-inner-icon="mdi-paperclip"
                  label="Seleccionar archivo PDF *"
                  outlined
                  :error-messages="errorArchivo"
                  @blur="$v.documentoNuevo.$touch()"
                ></v-file-input>
              </v-col>
              <v-col cols="4">
                <v-autocomplete 
                  v-model="tipoDocumento"
                  label="Seleccione el tipo del documento *"
                  item-value="id"
                  item-text="nombre"
                  :items="tipos"
                  no-data-text="Sin coincidencias"
                  outlined
                  clearable
                  :error-messages="errorTipos"
                  @click:clear="tipoDocumento = null"
                  @change="updateSelectedTipo"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn text @click="cerrarModalAgregar">Cancelar</v-btn>
            <v-btn color="primary" @click="confirmAddFtn">Guardar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <ConfirmationDialog
        :show="show_modal"
        title="¿Desea eliminar el documento seleccionado?"
        message="El cambio es irreversible, se estará registrando cada acción realizada"
        btnConfirmar="Continuar"
        @close="show_modal = false"
        @confirm="removeDocument"
      />
    </v-card-text>
  </v-card>
</template>
<script>
import ValidarEdicion from "../ValidarEdicion.vue";
import DataTableComponent from "@/components/DataTableComponent.vue";
import { EmptyComponent } from '@/components/utils';
import ArbolDocumentosProceso from '@/views/ProcesoCompraDoc/components/ArbolDocumentosProceso.vue';
import moment from 'moment';
import { mapState } from "vuex";
import { helpers, required } from "vuelidate/lib/validators";
import LegacyValidations from "@/utils/legacy-validations";
import { validationMixin } from "vuelidate";
import { Validator } from '@/utils/form-validation.js';
import ConfirmationDialog from "@/components/ConfirmationDialogComponent.vue";

const txtField = helpers.regex(
  "txtArea",
  /^[A-Za-z0-9-&.,:;()À-ÿ\u00f1\u00d1 ]*$/
);

function fileTypeValid(value) {
  if (!value) {
    return true;
  }
  let extension = /(\.pdf)$/i;
  let file = value;
  return extension.test(file.name);
}

function fileNameValid(value) {
  if (!value) {
    return true;
  }
  let regAlfaNumPdf = /^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ\-_\s]+(?=\.pdf$)/;
  let file = value;
  return regAlfaNumPdf.test(file.name);
}

export default {
  name: "ModificarDocumentosProceso",
  mixins: [validationMixin],
  components: {
    ValidarEdicion,
    DataTableComponent,
    EmptyComponent,
    ArbolDocumentosProceso,
    ConfirmationDialog,
  },
  data: () => ({
    validado: false,
    modalActualizar: false,
    modalAgregar: false,
    documentoNuevo: null,
    nombre: null,
    esEdicion: false,
    tipoDocumento: null,
    tipoDocumentoSeleccionado: null,
    documentoAnterior: [],
    documentsLoading: false,
    list_docs_var: [],
    tipos: [],
    borrado: false,
    show_modal: false,
    documentId: null,
  }),
  validations() {
    return {
      nombre: this.esEdicion ? {} : { required, txtField },
      tipoDocumento: this.esEdicion ? {} : { required },
      documentoNuevo: {
        required,
        fileTypeValid,
        fileNameValid,
        fileSizeValid: LegacyValidations.maxFileSizeRule(),
      },
    };
  },
  computed: {
    ...mapState("procesoCompraDoc", ["procesoData"]),
    id_proceso() {
      return +this?.$route?.params?.idProceso || 0;
    },
    processWithNoDocuments() {
      return !this.documentsLoading && this.list_docs_var.length === 0;
    },
    nombreArchivoError() {
      return new Validator(this.$v.nombre).detect().getResult();
    },
    errorArchivo() {
      return new Validator(this.$v.documentoNuevo).detect().getResult();
    },
    errorTipos() {
      return new Validator(this.$v.tipoDocumento).detect().getResult();
    },
  },
  methods: {
    validar(valor) {
      this.validado = valor;
    },
    formatDate(date) {
      return moment(date).format("DD-MM-YYYY HH:mm A");
    },
    filtrar() {
      this.FetchListDocs();
    },
    getRowClass(item) {
      if (item.eliminado) {
        return "highlight-row";
      }
      return "";
    },
    abrirModalUpdate(item) {
      this.documentoAnterior = item;
      this.esEdicion = true;
      this.modalActualizar = true;
    },
    abrirModalAgregar(){
      this.getTipoDocumentos();
      this.esEdicion = false;
      this.modalAgregar = true;
    },
    cerrarModalAgregar(){
      this.$v.$reset();
      this.documentoNuevo = null;
      this.nombre = null;
      this.tipoDocumento = null;
      this.modalAgregar = false;
    },
    cerrarModalUpdate(){
      this.$v.$reset();
      this.documentoNuevo = null;
      this.modalActualizar = false;
    },
    getAcciones(item) {
      return [
        {
          nombre: "Descargar documento",
          icono: "mdi-download",
          callback2: () => this.DownloadFileFtn(item),
        },
        {
          nombre: "Editar documento",
          icono: "mdi-file-document-edit",
          callback2: () => this.abrirModalUpdate(item),
          disabled: !this.validado || item.eliminado,
        },
        {
          nombre: "Eliminar documento",
          icono: "mdi-delete",
          callback2: () => {
            this.documentId = item.id;
            this.show_modal = true;
          },
          disabled: !this.validado || item.eliminado,
        },
      ];
    },
    async removeDocument() {
      try {
        const response = await this.services.PacProcesos.deleteDocumentoProcesoHerramienta(
        this.id_proceso,  
        this.documentId
        );
      this.show_modal = false;
      if (response.status === 200) {
          this.pushAppMessage({
            message: "Documento eliminado correctamente.",
            type: "success",
          });
          this.FetchListDocs();
        }
      } catch (error) {
        console.log('error :>> ', error);
        this.pushAppMessage({
          message: "Error al eliminar el documento",
          type: "error",
        });
      }
    },
    updateSelectedTipo() {
      this.tipoDocumentoSeleccionado = this.tipos.find(tipo => tipo.id === this.tipoDocumento) || null;
    },
    async getTipoDocumentos() {
      const response = await this.services.PacProcesos.getTiposDocumentsHerramienta(
          this.id_proceso
        );
      this.tipos = response?.data;
    },
    async DownloadFileFtn(item) {
      const response =
        await this.services.PacProcesos.descargarDocumentoProceso({
          ruta: item.ruta_documento, 
          disk: item.disk,
        })
      if (!response) {
        this.temporalAlert({
          show: true,
          message: "Ocurrió un error al descargar el documento",
          type: "error",
        });
        return;
      }

      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });

      const file = new File([blob], item.nombre_documento, {
        type: response.headers["content-type"],
      });

      const link = document.createElement("a");
      link.setAttribute("download", item.nombre_documento);
      link.href = window.URL.createObjectURL(file);
      link.download = "";
      link.click();
    },
    async confirmUpdateFtn(){
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const folder = this.documentoAnterior.ruta_documento.match(/^(.*)\/[^/]+\.pdf$/);
        let formData = new FormData();
        formData.append("documento", this.documentoNuevo);
        formData.append("nombre", this.documentoAnterior.nombre_documento);
        formData.append("id_proceso", this.id_proceso);
        formData.append("id_tipo_documento", this.documentoAnterior.id_tipo_documento);
        formData.append("folder", folder ? folder[1] : null);
        formData.append("disk", "local");
        formData.append("id_documento", this.documentoAnterior.id);
        const response =
          await this.services.PacProcesos.actualizarDocumentosProceso(formData);
        
        if (!response.status === 200) {
          this.temporalAlert({
            show: true,
            message: "Ocurrió un error al actualizar el documento",
            type: "error",
          });
          return;
        }
        this.temporalAlert({
          show: true,
          type: "success",
          message: "Documento actualizado correctamente",
        });
        this.cerrarModalUpdate();
        this.FetchListDocs();
      }
    },
    async confirmAddFtn(){
      this.$v.$touch();
      if (!this.$v.$invalid) {
        let formData = new FormData();
        formData.append("documento", this.documentoNuevo);
        formData.append("nombre", this.nombre);
        formData.append("id_proceso", this.id_proceso);
        formData.append("id_tipo_documento", this.tipoDocumento);
        formData.append("folder", this.tipoDocumentoSeleccionado.nombre);
        formData.append("disk", "local");
        const response =
          await this.services.PacProcesos.agregarDocumentosProceso(formData);
        
        if (!response.status === 200) {
          this.temporalAlert({
            show: true,
            message: "Ocurrió un error al agregar el documento",
            type: "error",
          });
          return;
        }
        this.temporalAlert({
          show: true,
          type: "success",
          message: "Documento agregado correctamente",
        });
        this.cerrarModalAgregar();
        this.FetchListDocs();
      }
    },
    setHeadersBasedOnRole() {
      this.HEADERS_CONST = [
        {
          align: 'center',
          text: "Documento",
          value: "nombre_documento",
        },
        {
          align: 'center',
          text: "Subido por",
          value: "Usuario.email",
        },
        {
          align: 'center',
          text: "Fecha de subida",
          value: "created_at",
        },
        {
          align: 'center',
          text: "Acciones",
          value: "acciones",
          sortable: false,
        },
      ];
    },

    async FetchListDocs() {
      try {
        this.documentsLoading = true;
        const RESP_SC_CONST = await this.services.PacProcesos.getListDocumentsHerramienta(
          this.id_proceso,
          { eliminado: this.borrado },
        );
        if (RESP_SC_CONST) {
          const { data } = RESP_SC_CONST;
          this.list_docs_var = data?.documentos ?? [];
          this.tecnico_asignado = data.empleado_asignado;
          this.setHeadersBasedOnRole();
        }
        this.list_docs = this.list_docs_var;
      } catch (error) {
        this.pushAppMessage({ message: 'Error al cargar los documentos.', type: 'error' });
      } finally {
        this.documentsLoading = false;
      }
    },
  },
  mounted() {
    this.FetchListDocs();
  },
};
</script>

<style>
.inputAdjunto .v-messages__message{
    color: #ff5252 !important;
  }
.highlight-row {
    background-color: #ecb36d !important;
  }
</style>