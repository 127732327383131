<template>
  <div v-if="!esCallCenter">
    <v-row v-if="subido">
      <v-col>
        <v-btn
          class="secondary"
          @click="
            (documento_visible = true),
              $refs.pdf.obtenerArchivo(ruta, 'logAuditoriaLotes')
          "
          >Ver último adjunto
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-file-input
          v-model="form.file"
          :label="
            subido
              ? 'Puede adjuntar un nuevo documento'
              : 'Por favor, primero adjunte el documento'
          "
          outlined
          rounded
          accept=".pdf"
          @change="validateFile"
        />
      </v-col>
      <v-col cols="12" md="6">
        <v-btn class="primary mt-2" @click.stop="subirModificativa">
          Enviar
        </v-btn>
      </v-col>
    </v-row>
    <PdfModalClienteViejo :permitirDescargar="true" ref="pdf" />
  </div>
</template>
<script>
import { convertToFormData } from "@/utils/data";
import PdfModalClienteViejo from "@/components/PdfModalClienteViejo.vue";

export default {
  name: "ValidarEdicion",
  data: () => ({
    form: {
      file: null,
    },
    subido: false,
    ruta: null,
    documento_visible: true,
  }),
  components: { PdfModalClienteViejo },
  computed: {
    esCallCenter() {
      return (
        this.haveRole("ROLE_CALLCENTER") && !this.haveRole("ROLE_SUPER_ADMIN")
      );
    },
  },
  methods: {
    async subirModificativa() {
      if (this.form.file === null) {
        return this.pushAppMessage({
          message: "Debe adjuntar el documento de modificativa",
          type: "warning",
        });
      }
      const formData = convertToFormData(this.form);
      await this.services.ModificativasProcesosServices.subirModificativa(
        this.id_proceso,
        this.id_modificativa,
        formData
      );
      this.form.file = null;
      await this.obtenerModificativa();
    },
    async obtenerModificativa() {
      const { data } =
        await this.services.ModificativasProcesosServices.validarModificativa(
          this.id_proceso,
          this.id_modificativa
        );
      this.subido = !!data?.subida;
      this.ruta = data?.modificacion?.adjunto;
      this.$emit("validar", this.subido);
    },
    async visualizarAdjunto() {
      this.documento_visible = true;
      await this.$refs.pdf.obtenerArchivo(this.ruta, "logAuditoriaLotes");
    },
    validateFile() {
      const file = this.form.file;
      if (file && file.type !== "application/pdf") {
        this.form.file = null;
        return this.pushAppMessage({
          message: "El documento debe ser pdf",
          type: "warning",
        });
      }
    },
  },
  async mounted() {
    await this.obtenerModificativa();
  },
  props: {
    id_proceso: {
      type: Number,
      required: true,
    },
    id_modificativa: {
      type: Number,
      required: true,
    },
  },
};
</script>
