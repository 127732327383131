<template>
  <section fluid class="mb-8">
    <h3 class="text-center text-sm-start">Configuración de lotes</h3>
    <p class="small-text mb-0">
      Después de agregar un lote se deben establecer las especificaciones dentro
      del lote.
    </p>
    <p class="small-text mb-0">
      La creación de especificaciones requiere de que se hayan agregado las
      solicitudes de compra correspondientes al proceso.
    </p>
    <v-row class="mt-4">
      <v-col cols="12" sm="4">
        <div class="text-center text-md-start pb-3">
          <v-col>
            <v-btn
              v-if="[11].includes(procesoData.id_seguimiento_proceso)"
              color="secondary"
              class="text-no-style button-extra text-center mx-auto"
              dark
              style="width: 100%"
              @click="setShowModalAgregarLote(true)"
            >
              <v-icon>mdi-plus</v-icon>
              Agregar lote
            </v-btn>
          </v-col>
        </div>

        <v-card class="mt-4">
          <v-card-text>
            <v-row>
              <v-col cols="12" md="9" class="pr-md-1 pr-lg-2">
                <v-text-field
                  outlined
                  dense
                  label="Buscar lote"
                  v-model="filterLote"
                  hide-details
                />
              </v-col>
              <v-col cols="12" md="3" class="pl-md-1 pl-lg-2">
                <v-btn
                  solid
                  block
                  color="secondary"
                  class="mb-3"
                  @click="
                    getLotes({
                      id_proceso_compra: $route.params.idProceso,
                      filters: filterLote,
                    })
                  "
                >
                  <v-icon color="white">mdi-magnify</v-icon>
                </v-btn>
              </v-col>
            </v-row>

            <p v-if="lotes.length === 0" class="text-center">
              No se han encontrado lotes configurados
            </p>

            <v-list
              nav
              dense
              class="px-0"
              style="max-height: 450px; overflow-y: auto"
            >
              <v-list-item-group v-model="loteSeleccionado" color="primary">
                <v-list-item v-for="(lote, i) in lotes" :key="i" outlined>
                  <v-list-item-content>
                    <v-list-item-title v-text="lote.nombre"></v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn
                      v-if="procesoData.id_seguimiento_proceso === 11"
                      icon
                      @click.prevent="
                        setShowModalDeleteLote(true), setIdLoteAccion(lote.id)
                      "
                    >
                      <v-icon
                        :color="loteSeleccionado === i ? 'white' : 'secondary'"
                        >mdi-delete</v-icon
                      >
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" sm="8">
        <v-card
          v-if="
            typeof loteSeleccionado == 'number' &&
            datosLoteSeleccionado.nombre !== null
          "
        >
          <v-card-text>
            <v-form>
              <v-text-field
                outlined
                label="Nombre del lote *"
                v-model="datosLoteSeleccionado.nombre"
              />
              <v-text-field
                outlined
                label="Descripción"
                v-model="datosLoteSeleccionado.descripcion"
              />
              <v-btn
                outlined
                color="secondary"
                class="mr-4"
                min-width="30%"
                @click="
                  actualizarDatosLote({
                    id_lote: datosLoteSeleccionado.id,
                    nombre: datosLoteSeleccionado.nombre,
                    descripcion: datosLoteSeleccionado.descripcion,
                    id_proceso_compra: datosLoteSeleccionado.id_proceso_compra,
                    filters: filterLote,
                  })
                "
                >Actualizar lote</v-btn
              >
              <v-btn
                outlined
                v-if="this.haveRole('ROLE_SUPER_ADMIN')"
                color="secondary"
                class="mr-4"
                min-width="30%"
                @click="
                  separarAgrupador({
                    id_lote: datosLoteSeleccionado.id,
                    id_proceso_compra: datosLoteSeleccionado.id_proceso_compra,
                  })
                "
                >Separar en lote por agrupador</v-btn
              >
              <v-btn
                :disabled="![11].includes(procesoData.id_seguimiento_proceso)"
                color="secondary"
                min-width="30%"
                v-if="[11].includes(procesoData.id_seguimiento_proceso)"
                @click="setShowModalAddEspecificacion(true)"
                >Agregar especificación</v-btn
              >
            </v-form>

            <v-divider class="mt-4 mb-4" />

            <v-data-table
              :headers="headers"
              :items="agrupacionesLote"
              no-data-text="No hay datos para mostrar"
            >
              <template #[`item.monto_sub_proceso_obs`]="{ item }">
                {{
                  Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 8,
                  }).format(item?.monto_sub_proceso_obs)
                }}
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <dialogoConfirmacion
      :show="showModalDeleteLote"
      title="¿Desea eliminar el lote seleccionado?"
      message="De confirmarse la siguiente acción, los cambios no serán reversibles."
      btnConfirmar="Continuar"
      @close="setShowModalDeleteLote(false), setIdLoteAccion(null)"
      @confirm="
        deleteLote({
          id_proceso_compra: $route.params.idProceso,
          filters: filterLote,
        }),
          (loteSeleccionado = null)
      "
    />
    <AgregarLoteComponent />
    <AgregarEspecificacionComponent />
  </section>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import AgregarLoteComponent from "./AgregarLoteComponent.vue";
import AgregarEspecificacionComponent from "./AgregarEspecificacionComponent.vue";
import dialogoConfirmacion from "@/components/ConfirmationDialogComponent.vue";
import { ConfirmationModalComponent } from "@/components/utils";
import DataTableComponent from "../../../components/DataTableComponent.vue";
import PdfModal from "@/components/PdfModal.vue";
import participacionesAuditoria from "../AuditoriaProcesos/components/ParticipantesComponent.vue";

export default {
  name: "ConfiguracionLotesComponent",
  components: {
    AgregarLoteComponent,
    AgregarEspecificacionComponent,
    dialogoConfirmacion,
    ConfirmationModalComponent,
    DataTableComponent,
    PdfModal,
    participacionesAuditoria,
  },
  props: {
    permiteEdicion: {
      type: Boolean,
    },
  },
  data: () => ({
    loteSeleccionado: null,
    filterLote: null,
  }),
  computed: {
    ...mapState("procesoCompraDoc", [
      "lotes",
      "procesoData",
      "datosLoteSeleccionado",
      "showModalDeleteLote",
    ]),
    agrupacionesLote() {
      return this.datosLoteSeleccionado?.SubProcesoObs || [];
    },
    headers() {
      const defaultHeaders = [
        { text: "Nombre", align: "center", value: "nombre_agrupacion" },
        { text: "Detalle", align: "center", value: "especificacion" },
        { text: "Cantidad", align: "right", value: "total_sub_proceso" },
        { text: "Monto", align: "right", value: "monto_sub_proceso_obs" },
      ];
      return defaultHeaders;
    },
  },
  methods: {
    ...mapMutations("procesoCompraDoc", [
      "setShowModalAgregarLote",
      "setShowModalAddEspecificacion",
      "setShowModalDeleteLote",
      "setIdLoteAccion",
    ]),
    ...mapActions("procesoCompraDoc", [
      "getLotes",
      "getLote",
      "actualizarDatosLote",
      "deleteLote",
      "separarAgrupador",
      "separarEspecifica",
    ]),
    ...mapActions("procesoCompra", [
      "getObsRecepcionOfertas",
      "getObsDetalleRecepcionOfertas",
      "getSubprocesoObsRecepcionOfertas",
    ]),
  },
  watch: {
    loteSeleccionado(value_param) {
      if (value_param != undefined) {
        this.getLote({ id_lote: this.lotes[value_param].id });
      }
    },
  },
  created: function () {
    this.getLotes({
      id_proceso_compra: this.$route.params.idProceso,
    });
  },
};
</script>

<style lang="scss" scoped>
.v-list-item {
  border: 1px solid #c2c2c2 !important;
  margin-bottom: 10px !important;
}

.v-list-item--active {
  color: white !important;
  border: 1px solid #4f5884 !important;
  background: var(--v-secondary-base) !important;
}

.small-text {
  font-size: 14px !important;
  color: #818181 !important;
}
</style>
