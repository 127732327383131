var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"mb-8",attrs:{"fluid":""}},[_c('h3',{staticClass:"text-center text-sm-start"},[_vm._v("Configuración de lotes")]),_c('p',{staticClass:"small-text mb-0"},[_vm._v(" Después de agregar un lote se deben establecer las especificaciones dentro del lote. ")]),_c('p',{staticClass:"small-text mb-0"},[_vm._v(" La creación de especificaciones requiere de que se hayan agregado las solicitudes de compra correspondientes al proceso. ")]),_c('v-row',{staticClass:"mt-4"},[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('div',{staticClass:"text-center text-md-start pb-3"},[_c('v-col',[([11].includes(_vm.procesoData.id_seguimiento_proceso))?_c('v-btn',{staticClass:"text-no-style button-extra text-center mx-auto",staticStyle:{"width":"100%"},attrs:{"color":"secondary","dark":""},on:{"click":function($event){return _vm.setShowModalAgregarLote(true)}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" Agregar lote ")],1):_vm._e()],1)],1),_c('v-card',{staticClass:"mt-4"},[_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"pr-md-1 pr-lg-2",attrs:{"cols":"12","md":"9"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"Buscar lote","hide-details":""},model:{value:(_vm.filterLote),callback:function ($$v) {_vm.filterLote=$$v},expression:"filterLote"}})],1),_c('v-col',{staticClass:"pl-md-1 pl-lg-2",attrs:{"cols":"12","md":"3"}},[_c('v-btn',{staticClass:"mb-3",attrs:{"solid":"","block":"","color":"secondary"},on:{"click":function($event){return _vm.getLotes({
                    id_proceso_compra: _vm.$route.params.idProceso,
                    filters: _vm.filterLote,
                  })}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-magnify")])],1)],1)],1),(_vm.lotes.length === 0)?_c('p',{staticClass:"text-center"},[_vm._v(" No se han encontrado lotes configurados ")]):_vm._e(),_c('v-list',{staticClass:"px-0",staticStyle:{"max-height":"450px","overflow-y":"auto"},attrs:{"nav":"","dense":""}},[_c('v-list-item-group',{attrs:{"color":"primary"},model:{value:(_vm.loteSeleccionado),callback:function ($$v) {_vm.loteSeleccionado=$$v},expression:"loteSeleccionado"}},_vm._l((_vm.lotes),function(lote,i){return _c('v-list-item',{key:i,attrs:{"outlined":""}},[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(lote.nombre)}})],1),_c('v-list-item-action',[(_vm.procesoData.id_seguimiento_proceso === 11)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.preventDefault();_vm.setShowModalDeleteLote(true), _vm.setIdLoteAccion(lote.id)}}},[_c('v-icon',{attrs:{"color":_vm.loteSeleccionado === i ? 'white' : 'secondary'}},[_vm._v("mdi-delete")])],1):_vm._e()],1)],1)}),1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"8"}},[(
          typeof _vm.loteSeleccionado == 'number' &&
          _vm.datosLoteSeleccionado.nombre !== null
        )?_c('v-card',[_c('v-card-text',[_c('v-form',[_c('v-text-field',{attrs:{"outlined":"","label":"Nombre del lote *"},model:{value:(_vm.datosLoteSeleccionado.nombre),callback:function ($$v) {_vm.$set(_vm.datosLoteSeleccionado, "nombre", $$v)},expression:"datosLoteSeleccionado.nombre"}}),_c('v-text-field',{attrs:{"outlined":"","label":"Descripción"},model:{value:(_vm.datosLoteSeleccionado.descripcion),callback:function ($$v) {_vm.$set(_vm.datosLoteSeleccionado, "descripcion", $$v)},expression:"datosLoteSeleccionado.descripcion"}}),_c('v-btn',{staticClass:"mr-4",attrs:{"outlined":"","color":"secondary","min-width":"30%"},on:{"click":function($event){return _vm.actualizarDatosLote({
                  id_lote: _vm.datosLoteSeleccionado.id,
                  nombre: _vm.datosLoteSeleccionado.nombre,
                  descripcion: _vm.datosLoteSeleccionado.descripcion,
                  id_proceso_compra: _vm.datosLoteSeleccionado.id_proceso_compra,
                  filters: _vm.filterLote,
                })}}},[_vm._v("Actualizar lote")]),(this.haveRole('ROLE_SUPER_ADMIN'))?_c('v-btn',{staticClass:"mr-4",attrs:{"outlined":"","color":"secondary","min-width":"30%"},on:{"click":function($event){return _vm.separarAgrupador({
                  id_lote: _vm.datosLoteSeleccionado.id,
                  id_proceso_compra: _vm.datosLoteSeleccionado.id_proceso_compra,
                })}}},[_vm._v("Separar en lote por agrupador")]):_vm._e(),([11].includes(_vm.procesoData.id_seguimiento_proceso))?_c('v-btn',{attrs:{"disabled":![11].includes(_vm.procesoData.id_seguimiento_proceso),"color":"secondary","min-width":"30%"},on:{"click":function($event){return _vm.setShowModalAddEspecificacion(true)}}},[_vm._v("Agregar especificación")]):_vm._e()],1),_c('v-divider',{staticClass:"mt-4 mb-4"}),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.agrupacionesLote,"no-data-text":"No hay datos para mostrar"},scopedSlots:_vm._u([{key:`item.monto_sub_proceso_obs`,fn:function({ item }){return [_vm._v(" "+_vm._s(Intl.NumberFormat("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 2, maximumFractionDigits: 8, }).format(item?.monto_sub_proceso_obs))+" ")]}}],null,true)})],1)],1):_vm._e()],1)],1),_c('dialogoConfirmacion',{attrs:{"show":_vm.showModalDeleteLote,"title":"¿Desea eliminar el lote seleccionado?","message":"De confirmarse la siguiente acción, los cambios no serán reversibles.","btnConfirmar":"Continuar"},on:{"close":function($event){_vm.setShowModalDeleteLote(false), _vm.setIdLoteAccion(null)},"confirm":function($event){_vm.deleteLote({
        id_proceso_compra: _vm.$route.params.idProceso,
        filters: _vm.filterLote,
      }),
        (_vm.loteSeleccionado = null)}}}),_c('AgregarLoteComponent'),_c('AgregarEspecificacionComponent')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }